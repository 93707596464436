var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c("vx-card", { staticClass: "mb-5" }, [
          _c("div", { staticClass: "vx-row align-items-end" }, [
            _c("div", { staticClass: "order-filter-item" }, [
              _c(
                "div",
                { staticClass: "selectedDate-dropdown" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Date to search"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      value: _vm.selectedDate,
                      clearable: false,
                      options: _vm.selectDateList,
                    },
                    on: { input: _vm.setSelectedDate },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "order-filter-item" }, [
              _c(
                "div",
                { staticClass: "datepicker-item" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Date Range"),
                  ]),
                  _c("flat-pickr", {
                    staticClass: "w-full",
                    attrs: {
                      config: {
                        dateFormat: "Y-m-d",
                        mode: "range",
                        showMonths: 2,
                      },
                      name: "orderdate",
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "order-filter-item" }, [
              _c(
                "div",
                { staticClass: "agent-dropdown" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Agent/Staff Member"),
                  ]),
                  _c("v-select", {
                    attrs: { reduce: (x) => x.code, options: _vm.agentList },
                    model: {
                      value: _vm.agentId,
                      callback: function ($$v) {
                        _vm.agentId = $$v
                      },
                      expression: "agentId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "order-filter-item" }, [
              _c("div", { staticClass: "order-status" }, [
                _c(
                  "div",
                  { staticClass: "agent-dropdown" },
                  [
                    _c("label", { staticClass: "text-sm" }, [
                      _vm._v("Order Status (clear to show all)"),
                    ]),
                    _c("v-select", {
                      attrs: { reduce: (x) => x.code, options: _vm.statusList },
                      model: {
                        value: _vm.orderStatusId,
                        callback: function ($$v) {
                          _vm.orderStatusId = $$v
                        },
                        expression: "orderStatusId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "order-filter-item" }, [
              _c(
                "div",
                { staticClass: "text-field" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:50",
                        expression: "'max:50'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      label:
                        "Name/Order Ref/Booking Ref/Voucher Code/Marketing Source",
                      name: "Name/Order Ref/Booking Ref",
                    },
                    model: {
                      value: _vm.searchtext,
                      callback: function ($$v) {
                        _vm.searchtext = $$v
                      },
                      expression: "searchtext",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "order-filter-item" },
              [
                _c("vs-button", { on: { click: _vm.searchOrders } }, [
                  _vm._v("Search"),
                ]),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-2",
                    attrs: { type: "border" },
                    on: { click: _vm.exportCsv },
                  },
                  [_vm._v("Export")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "vx-card",
          [
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.ordersList,
                gridOptions: _vm.gridOptions,
                paginationPageSize: _vm.paginationPageSize,
                pagination: true,
                enableCellTextSelection: true,
                id: "order-grid",
              },
              on: {
                "first-data-rendered": _vm.onFirstDataRendered,
                "grid-size-changed": _vm.onGridSizeChanged,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }